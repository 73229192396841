import React from 'react'

const InfoBox = () => {
    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto">
                <h1 class="mb-4 text-2xl font-extrabold text-gray-800 md:mb-6 lg:text-4xl text-center">WARUNKI PRZYJĘCIA DO OŚRODKA</h1>
                <div class="flex flex-wrap -m-4 ">
                    <div class="p-4 w-full">
                        <div class="flex h-full bg-orange-400 p-8 rounded shadow-md">
                            <div className="flex items-center justify-center h-12 aspect-square bg-orange-500 mr-8 text-4xl text-white font-extrabold shadow-md rounded-lg">i</div>
                            <p class="leading-relaxed mb-6 text-white text-xl">Aby stać się pacjentem ośrodka terapii ALKOMEDIC należy podjąć gotowość do przeprowadzenia detoksu czyli procesu oczyszczania organizmu, a także wyrazić zgodę na podjęcie leczenia w naszym ośrodku.
                                Podczas wstępnej rozmowy telefonicznej przeprowadzony zostaje wywiad na temat obecnego stanu pacjentaoraz ustalony termin przyjęcia do ośrodka.
                                Terapia zależy od indywidualnych potrzeb i może obejmować detoks alkoholowy, narkotykowy lub lekowy oraz terapię uzależnień lub wyłącznie detoks bądź tylko psychoterapię.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoBox