import React from 'react'

const GlobalAlert = () => {
    return (
        <div class="h-full bg-red-500 p-2">
            <p class="leading-relaxed text-xs text-white md:text-lg text-center">Informujemy, że Alkomedic przyjmuje rezerwacje terminów na styczeń 2025</p>
        </div>
    )
}

export default GlobalAlert