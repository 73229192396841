import React from 'react'
import { Appointment } from '../../components';
import heroBackground_image from '../../../assets/images/hero-bg-min.png'
const HeroCTA = ({ props }) => {

    if (!props) {
        return;
    }

    const { title, subTitle } = props

    return (
        <>
            <div className="relative md:h-[calc(100vh_-_124px)] overflow-hidden"
                style={{
                    backgroundImage: `url(${heroBackground_image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <div className="relative bg-black bg-opacity-30 h-full p-4">
                    <div className="h-full flex flex-col lg:flex-row justify-between items-center mx-auto max-w-screen-xl xl:max-w-screen-2xl">
                        <div className="px-4 md:px-8 py-12 xl:px-12 md:py-0">
                            <h2 style={{ textShadow: '1px 1px 1px #000' }} className="mb-6 max-w-lg font-sans text-3xl font-bold tracking-tight text-white sm:text-6xl sm:leading-none">{title || ""}</h2>
                            <p style={{ textShadow: '1px 1px 1px #000' }} className="mb-4 max-w-xl text-base text-gray-50 md:text-lg" dangerouslySetInnerHTML={{ __html: subTitle }}></p>
                            <div className="drop-shadow-2xl">
                            </div>
                        </div>
                        <Appointment />
                    </div>
                </div>
            </div>

        </>
    )
}

export default HeroCTA