export const Config = {
    baseURL: "https://alkomedic.pl",
    origin: "alkomedic.pl",
    siteName: "alkomedic",
    phone:{
        phone1: "794 002 505",
        phone2: "794 002 560"
    },
    email: {
        contact: "kontakt@alkomedic.pl",
        pomoc: "pomoc@alkomedic.pl"
    }
}