import React from 'react'
import z1 from '../../../assets/images/1-min.png'

const ContentWithImage = () => {
  return (
    <section className="bg-white">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg">
          <h2 className="mb-4 text-2xl font-extrabold text-gray-800 md:mb-6 lg:text-4xl">Kilka słów o nas</h2>
          <p className="mb-4 text-justify	">Nasz ośrodek położony jest w malowniczej części podkarpacia u podnóża bramy bieszczad. Cisza i spokój starego, 1,5 hektarowego parku pozwala otulić się naturą i znaleźć ukojenie dla ciała i umysłu, a także odciąć się od wielkomiejskiego zgiełku.Nasi pacjenci mogą również swobodnie korzystać z dobrodziejstw starego sadu i przydomowego ziołowo-warzywnego ogrodu. Unikatowy klimat i czyste powietrze kojąco wpływają na rekonwalescencję.
            <br />
            Ponadto oferujemy różne formy rekreacji:
            <ul className='list-disc ml-5 my-4'>
              <li>siłownia</li>
              <li>tenis stołowy </li>
              <li>kącik szachowy</li>
              <li>siatkówka ogrodowa</li>
              <li>kameralna biblioteka</li>
              <li> wycieczki fakultatywne do najpiękniejszych miejsc regionu podkarpackiego.</li>
            </ul>
            Terapia odbywa się we wnętrzach klimatycznego XIX wiecznego dworku.
            Przywróć swoje zdrowie w otoczeniu pięknego starodrzewia w miejscu z niepowtarzalnym klimatem.
          </p>
        </div>
        <div className="grid grid-cols-1 mt-8">
          <img className="w-full h-[395px] object-cover hidden sm:block rounded-xl shadow-xl" src={z1} alt="Dworek"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-offset="100"
          />

        </div>
      </div>
    </section>
  )
}

export default ContentWithImage