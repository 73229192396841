import React, { useState } from 'react';
import { Button } from '../../components';
import { Link } from 'react-router-dom';
import { Config } from '../../../config/Config';

const Appointment = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        addictionType: '',
        acceptTerms: false,
    });

    const [errors, setErrors] = useState({
        name: false,
        phone: false,
        email: false,
        addictionType: false,
        acceptTerms: false, 
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false, 
        }));
    };

    const handleSubmit = async () => {
        const newErrors = {
            name: !formData.name.trim(),
            phone: !formData.phone.trim(),
            email: !formData.email.trim(),
            addictionType: !formData.addictionType.trim(),
            acceptTerms: !formData.acceptTerms,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        try {
            const response = await fetch(`${Config.API_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Wystąpił błąd podczas wysyłania formularza.');
            }

            const result = await response.json();
            alert('Formularz został pomyślnie wysłany!');
            console.log(result);

            setFormData({
                name: '',
                phone: '',
                email: '',
                addictionType: '',
                acceptTerms: false,
            });
            setErrors({
                name: false,
                phone: false,
                email: false,
                addictionType: false,
                acceptTerms: false,
            });
        } catch (error) {
            console.error('Błąd:', error);
        }
    };

    return (
        <>
            <div
                className="w-full max-w-xl xl:w-5/12 xl:px-8"
                data-aos="fade-left"
                data-aos-delay="2000"
                data-aos-offset="0"
            >
                <div className="text-center overflow-hidden bg-white/[0.95] p-7 shadow-2xl sm:p-10 rounded-lg lg:max-w-md mx-auto mb-4">
                    <h2 className="font-bold text-gray-800 lg:text-xl">
                        Umów się na bezpłatną konsultację w naszym ośrodku
                    </h2>
                    <span className="font-light text-gray-500 block mb-4">
                        Wypełnij dane w formularzu i wyślij – nasz terapeuta skontaktuje się z Tobą aby ustalić dogodny
                        termin.
                    </span>
                    <div className="mb-4">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Imię"
                            className={`w-full rounded-lg border p-3 text-gray-700 focus:outline-none focus:ring-1 ${errors.name ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                                }`}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Telefon"
                            className={`w-full rounded-lg border p-3 text-gray-700 focus:outline-none focus:ring-1 ${errors.phone ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                                }`}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="E-mail"
                            className={`w-full rounded-lg border p-3 text-gray-700 focus:outline-none focus:ring-1 ${errors.email ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                                }`}
                        />
                    </div>
                    <div>
                        <select
                            name="addictionType"
                            value={formData.addictionType}
                            onChange={handleInputChange}
                            className={`w-full rounded-lg border p-3 text-gray-700 bg-white focus:outline-none focus:ring-1 ${errors.addictionType ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                                }`}
                        >
                            <option value="">Wybierz rodzaj uzależnienia</option>
                            <option value="Alkoholizm">Alkoholizm</option>
                            <option value="Narkomania">Narkomania</option>
                            <option value="Uzależnienie od leków">Uzależnienie od leków</option>
                            <option value="Hazard">Hazard</option>
                            <option value="Seksoholizm">Seksoholizm</option>
                            <option value="Uzależnienia mieszane">Uzależnienia mieszane</option>
                        </select>
                    </div>
                    <div className="mt-4 flex items-center">
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            id="acceptTerms"
                            checked={formData.acceptTerms}
                            onChange={handleInputChange}
                            className={`mr-2 ${errors.acceptTerms ? 'border-red-500' : 'border-gray-300'
                                }`}
                        />
                        <label htmlFor="acceptTerms" className="text-gray-700 text-sm">
                            Akceptuję regulamin
                        </label>
                    </div>
                    {errors.acceptTerms && (
                        <p className="text-red-500 text-xs mt-1">Musisz zaakceptować regulamin, aby wysłać formularz.</p>
                    )}
                    <div className="mt-4">
                        <Button label="Wyślij" onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Appointment;
