import React from 'react'
import { Alert } from '../../components'
import ctaBackground from '../../../assets/images/image01-min.jpg';
const SimpleCta = ({ props }) => {

    if (!props) {
        return (
            <div className='px-24 mx-auto'>
                <Alert title='Error' message='Missing props in SimpleCta component.' type='error' visible={true} />
            </div>
        )
    }

    const { title, subTitle } = props;

    return (
        <div className="relative">
            <img src={ctaBackground} className="absolute inset-0 h-full w-full object-cover" alt="" />
            <div className="relative bg-black bg-opacity-25">
                <div className="absolute inset-x-0 bottom-0">
                    <svg viewBox="0 0 224 12" fill="#fff" className="w-full -mb-1 text-white" preserveAspectRatio="none">
                        <path
                            d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z">
                        </path>
                    </svg>
                </div>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                        <h2 className="mb-6 text-2xl font-extrabold text-white md:mb-6 lg:text-4xl text-center">
                            {title}
                        </h2>
                        <p className="mb-6 max-w-xl text-base text-gray-200 md:text-lg mx-auto">
                            {subTitle}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimpleCta