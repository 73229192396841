import React from 'react'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config'
import { Footer, GalleryImages, NavBar, SimpleCta, SimpleHero, VideoPlayer } from '../../components/components'
import data from '../../data/navigation.tsx'
import GlobalAlert from '../../components/globalAlert/GlobalAlert.jsx'

const Gallery = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alkomedic.pl - Galeria</title>
        <link rel="canonical" href={Config.baseURL + window.location.pathname} />
      </Helmet>
      <GlobalAlert />
      <NavBar current={"/galeria"} props={data} />
      <SimpleHero props={
        {
          title: "Galeria",
          span: "Zapraszamy do obejrzenia naszej galerii, gdzie można zobaczyć wnętrza ośrodka i otaczającą go spokojną naturę, które wspierają proces zdrowienia. Poznaj miejsca, w których dbamy o komfort i spokój naszych pacjentów.",
          image: "http://alkomedic.pl/upload/20.png"
        }
      } />

      <div className="mt-24">
        <VideoPlayer source={"http://alkomedic.pl/upload/domek.mp4"} />
      </div>

      <GalleryImages props={{
        title: "CO OFERUJE NASZ OŚRODEK",
        subTitle: "",
        images: [
          {
            id: "1",
            src: 'http://alkomedic.pl/upload/1.png',
            span: 'Alkomedic kwiaty'
          },
          {
            id: "2",
            src: 'http://alkomedic.pl/upload/2.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "3",
            src: 'http://alkomedic.pl/upload/3.png',
            span: 'Alkomedic park'
          },
          {
            id: "4",
            src: 'http://alkomedic.pl/upload/6.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "5",
            src: 'http://alkomedic.pl/upload/7.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "6",
            src: 'http://alkomedic.pl/upload/8.png',
            span: 'Alkomedic park'
          },
          {
            id: "7",
            src: 'http://alkomedic.pl/upload/9.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "8",
            src: 'http://alkomedic.pl/upload/10.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "9",
            src: 'http://alkomedic.pl/upload/11.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "10",
            src: 'http://alkomedic.pl/upload/12.png',
            span: 'Alkomedic dworek'
          },
          {
            id: "11",
            src: 'http://alkomedic.pl/upload/13.png',
            span: 'Alkomedic ogrod'
          },
          {
            id: "12",
            src: 'http://alkomedic.pl/upload/14.png',
            span: 'Alkomedic fontanna'
          },
          {
            id: "13",
            src: 'http://alkomedic.pl/upload/15.png',
            span: 'Alkomedic las'
          },
          {
            id: "14",
            src: 'http://alkomedic.pl/upload/16.png',
            span: 'Alkomedic park'
          },
          {
            id: "15",
            src: 'http://alkomedic.pl/upload/17.png',
            span: 'Alkomedic park'
          },
          {
            id: "16",
            src: 'http://alkomedic.pl/upload/18.png',
            span: 'Alkomedic park'
          },
          {
            id: "17",
            src: 'http://alkomedic.pl/upload/19.png',
            span: 'Alkomedic park'
          },
        ]
      }} />

      <SimpleCta props={
        {
          title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
          subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
        }
      } />
      <Footer />
    </>
  )
}

export default Gallery