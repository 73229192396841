import React from 'react'
import { Footer, NavBar, SimpleHero, PricingSimple, CheckoutPayment, Payment } from '../../components/components'
import data from '../../data/navigation.tsx'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
import InfoBox from '../../components/info/InfoBox.jsx'
import GlobalAlert from '../../components/globalAlert/GlobalAlert.jsx'
const Pricing = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alkomedic.pl - Cennik</title>
                <link rel="canonical" href={Config.baseURL + window.location.pathname} />
            </Helmet>
            <GlobalAlert />
            <NavBar current={"/cennik"} props={data} />
            <SimpleHero props={
                {
                    title: "Cennik",
                    span: "Sprawdź nasz przejrzysty i konkurencyjny cennik. Dowiedz się więcej o kosztach i pakietach, które sprawiają, że nasze usługi terapeutyczne i detoksykacyjne są dostępne dla każdego.",
                    image: "https://images.unsplash.com/photo-1579208575657-c595a05383b7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <PricingSimple props={
                {
                    title: "TERAPIA STACJONARNA (zamknięta)",
                    span: "",
                    price: 1000,
                    table: [
                        ["CZAS TRWANIA", "CENA", "PROMOCJA*"],
                        ["4 tygodnie (28 dni)", "10 000 zł", "7 900 zł*"],
                        ["6 tygodni (42 dni)", "15 000 zł", "12 500 zł*"],
                        ["8 tygodni (56 dni)", "20 000 zł", "16 000 zł*"],
                    ]
                }
            } />
            <PricingSimple props={
                {
                    title: "TERAPIA DZIENNA (otwarta)",
                    span: "",
                    price: 1000,
                    table: [
                        ["CZAS TRWANIA", "CENA", "PROMOCJA*",],
                        ["4 tygodnie (28 dni)", "8 000 zł", "7 000 zł*"],
                        ["6 tygodni (42 dni)", "10 000 zł", "9 500 zł*"],
                        ["8 tygodni (56 dni)", "23 000 zł", "11 000 zł*"],
                    ]
                }
            } />
            <PricingSimple props={
                {
                    title: "TERAPIA AMBULATORYJNA",
                    span: "",
                    price: 1000,
                    table: [
                        ["Usługa", "CENA"],
                        ["Grupa wsparcia dla rodzin i bliskich osób uzależnionych. Co druga niedziela godzina 15:30", "2 godziny – 150zł / osoba"],
                        ["Grupowa pogłębiona psychoterapia uzależnień dla osób, które ukończyły podstawowy program psychoterapii uzależnień. Trzecia sobota miesiąca godzina 14:00", "3 godziny – 200zł / osoba"],
                        ["Grupa motywacyjna. Co drugi piątek godzina 18:00", "1,5 godziny – 130zł / osoba"],
                        ["Indywidualna pogłębiona psychoterapia uzależnień w nurcie behawioralno - poznawczym z elementami pracy systemowej. Terminy ustalane indywidualnie", "1 godzina – 200zł"],
                        ["KLUB PACJENTA – spotkania dla osób które ukończyły podstawowy program terapeutyczny w Ośrodku Alkomedic. W każdą ostatnią sobotę miesiąca", "Bezpłatnie"],
                    ]
                }
            } />

            <Payment />
            <Footer />
        </>
    )
}

export default Pricing