import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArticleContent, Footer, NavBar, RateStar, SimpleCta, SimpleHero } from '../../components/components';
import data from '../../data/navigation.tsx';
import articles from '../../data/article/article.jsx';
import { Config } from '../../config/Config.js';
import { Helmet } from 'react-helmet';
import GlobalAlert from '../../components/globalAlert/GlobalAlert.jsx';
const Article = ({ current }) => {
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getArticle = (articleId) => {
            return articles[articleId];
        };

        const fetchedArticle = getArticle(articleId);

        if (!fetchedArticle) {
            navigate('/404');
        } else {
            setArticle(fetchedArticle);
        }
    }, [articleId, navigate]);

    if (!article) {
        return <div></div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alkomedic.pl - {article.title}</title>
                <link rel="canonical" href={Config.baseURL + window.location.pathname} />
            </Helmet>
            <GlobalAlert />
            <NavBar props={data} current={current} />
            <SimpleHero props={{
                title: article.title,
                span: article.subTitle,
                image: article.contentImage
            }} />
            <ArticleContent props={article.content} />
            <RateStar />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    );
};

export default Article;