import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <section className="flex items-center h-svh p-16 dark:bg-gray-50 dark:text-gray-800">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                    <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-400">
                        <span className="sr-only">Error</span>404
                    </h2>
                    <p className="text-2xl font-semibold md:text-3xl">Przepraszamy, nie mogliśmy znaleźć tej strony.</p>
                    <Link to={"#"} onClick={()=>{navigate(-2)}} rel="noopener noreferrer" href="#" className="relative inline-flex h-12 w-full items-center justify-center bg-lime-600 px-6 font-medium tracking-wide text-white shadow-md ring-lime-200 transition duration-200 hover:bg-lime-700 focus:outline-none focus:ring">Powrót do artykułu</Link>
                    <span className='block text-xs text-gray-600 sm:text-sm my-4'>lub</span>
                    <Link to={"/"} rel="noopener noreferrer" href="#" className="relative inline-flex w-full items-center justify-center bg-transparent px-6 font-medium tracking-wide text-lime-600 ring-lime-200 transition duration-200 hover:text-lime-700 focus:outline-none focus:ring">Powrót do strony głównej</Link>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound