import React from 'react'
import { Footer, NavBar, SimpleHero, Features, SimpleCta } from '../../components/components'
import data from '../../data/navigation.tsx'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
import GlobalAlert from '../../components/globalAlert/GlobalAlert.jsx'
const Support = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alkomedic.pl - Wsparcie</title>
                <link rel="canonical" href={Config.baseURL + window.location.pathname} />
            </Helmet>
            <GlobalAlert />
            <NavBar current={"/wsparcie"} props={data} />
            <SimpleHero props={
                {
                    title: "Wsparcie",
                    span: "Odkryj nasze grupy wsparcia dla rodzin, klub pacjenta oraz grupy motywacyjne. Tworzymy bezpieczne środowisko, które wspiera budowanie silnych więzi i radzenie sobie z uzależnieniem.",
                    image: "https://images.unsplash.com/photo-1511632765486-a01980e01a18?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <Features props={
                {
                    title: "RODZAJE WSPARCIA KTÓRE OFERUJEMY",
                    subTitle: "Nasz ośrodek oferuje różnorodne formy wsparcia, które są dostosowane do potrzeb naszych pacjentów. Wspieramy ich poprzez grupy wsparcia dla rodzin, klub pacjenta oraz grupy motywacyjne, tworząc bezpieczne środowisko sprzyjające zdrowieniu i powrotowi do pełni życia.",
                    content: [
                        {
                            "id": "1",
                            "title": "Terapia indywidualna",
                            "content": "Dedykowane podejście, które umożliwia głębsze zrozumienie i skuteczne zarządzanie osobistymi wyzwaniami związanymi z uzależnieniem.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "terapia_indywidualana",
                            "image": "https://images.unsplash.com/photo-1493836512294-502baa1986e2?q=80&w=2090&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Klub pacjenta",
                            "content": "Dołącz do naszego Klubu Pacjenta, gdzie znajdziesz wsparcie oraz inspirującą atmosferę do wspólnej walki z uzależnieniem.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "klub_pacjenta",
                            "image": "https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "3",
                            "title": "Grupa motywacyjna",
                            "content": "Nasza Grupa Motywacyjna to bezpieczne miejsce, gdzie wzajemnie motywujemy się do pokonywania wyzwań związanych z uzależnieniem.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "grupa_motywacyjna",
                            "image": "https://images.unsplash.com/photo-1464207687429-7505649dae38?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "4",
                            "title": "Terapia nawrotów",
                            "content": "Terapia nawrotów w naszym ośrodku pomaga pacjentom zrozumieć i zarządzać potencjalnymi nawrotami uzależnienia, zapewniając wsparcie i strategie potrzebne do utrzymania zdrowego i stabilnego życia.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "terapia_nawrotow",
                            "image": "https://images.pexels.com/photos/1134204/pexels-photo-1134204.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        },
                        {
                            "id": "5",
                            "title": "Grupa wsparcia dla rodzin",
                            "content": "Nasza Grupa Wsparcia dla Rodzin oferuje wsparcie i bezpieczne środowisko do dzielenia się doświadczeniami oraz nauki skutecznych strategii wspierających zdrowienie i rehabilitację bliskich.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "grupa_wsparcia_dla_rodzin",
                            "image": "https://images.pexels.com/photos/1648387/pexels-photo-1648387.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        },
                        {
                            "id": "6",
                            "title": "Inne",
                            "content": "Znajdziesz tutaj różnorodne usługi i wsparcie, dopasowane do indywidualnych potrzeb i sytuacji naszych pacjentów.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "inne",
                            "image": "https://plus.unsplash.com/premium_photo-1665990294271-819b62445015?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                    ]
                }
            } />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    )
}

export default Support