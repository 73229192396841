const data = {
    navigationLinks: [
        {
            "id": 1,
            "href": "/",
            "label": "Start"
        },
        {
            "id": 2,
            "href": "/onas",
            "label": "O nas",
        },
        // {
        //     "id": 3,
        //     "href": "/detoks",
        //     "label": "Detoks"
        // },
        {
            "id": 4,
            "href": "/terapia",
            "label": "Terapia"
        },
        {
            "id": 5,
            "href": "/wsparcie",
            "label": "Wsparcie"
        },
        {
            "id": 6,
            "href": "/cennik",
            "label": "Cennik"
        },
        {
            "id": 7,
            "href": "/galeria",
            "label": "Galeria"
        },
        {
            "id": 7,
            "href": "/",
            "label": "Blog"
        },
        {
            "id": 8,
            "href": "/kontakt",
            "label": "Kontakt"
        }
    ]

}

export default data;