import React from 'react'
import image_visa from '../../../assets/images/visa.svg';
import image_mastercard from '../../../assets/images/mastercard.svg';
import image_blik from '../../../assets/images/blik.svg';

const Payment = () => {
    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Akceptowalne metody płatności</h2>
                    <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">W ośrodku Alkomedic płatności mogą Państwo zrealizować
                        gotówką, przelewem, kartą lub blikiem</p>

                </div>

                <div className="grid grid-cols-3 gap-8 md:grid-cols-3 md:gap-0 md:divide-x">
                    <div className="flex flex-col items-center justify-center md:p-4"
                        data-aos="fade-up"
                        data-aos-delay={100}
                        data-aos-offset="100"
                    >
                        <img src={image_visa} height={100} />
                    </div>

                    <div className="flex flex-col items-center md:p-4"
                        data-aos="fade-up"
                        data-aos-delay={150}
                        data-aos-offset="100"
                    >
                        <img src={image_mastercard} height={100} />
                    </div>

                    <div className="flex flex-col items-center md:p-4"
                        data-aos="fade-up"
                        data-aos-delay={200}
                        data-aos-offset="100"
                    >
                        <img src={image_blik} height={100} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment