import React from 'react'
import { Footer, NavBar, SimpleHero, Features, SimpleCta, ContentWithImage, VideoPlayer } from '../../components/components'
import data from '../../data/navigation.tsx'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
import GlobalAlert from '../../components/globalAlert/GlobalAlert.jsx'
const AboutUs = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alkomedic.pl - O nas</title>
                <link rel="canonical" href={Config.baseURL + window.location.pathname} />
            </Helmet>
            <GlobalAlert />
            <NavBar current={"/onas"} props={data} />
            <SimpleHero props={
                {
                    title: "O nas",
                    span: "Poznaj nasz prywatny ośrodek leczenia uzależnień, gdzie indywidualne podejście i kompleksowa opieka są priorytetem. Dowiedz się, jak nasz doświadczony zespół może pomóc Ci odzyskać zdrowie i kontrolę nad życiem.",
                    image: "http://alkomedic.pl/upload/22.png"
                }
            } />
            <ContentWithImage />
            <VideoPlayer source={"http://alkomedic.pl/upload/domek.mp4"} />
            <Features props={
                {
                    title: "",
                    subTitle: "",
                    content: [
                        {
                            "id": "1",
                            "title": "Zarezerwuj termin",
                            "content": "Nie czekaj, zrób pierwszy krok ku zdrowiu. Zarezerwuj termin w naszym ośrodku już dziś i rozpocznij swoją drogę do wolności od uzależnień. Nasz zespół jest gotowy, aby Ci pomóc.",
                            "action": "Zobacz więcej",
                            "category": "",
                            "link": "/kontakt",
                            "image": "https://images.unsplash.com/photo-1506784365847-bbad939e9335?q=80&w=2068&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Umów się na rozmowę",
                            "content": "Zrób pierwszy krok ku zdrowiu – umów się na rozmowę z naszym specjalistą. Jesteśmy tutaj, aby odpowiedzieć na Twoje pytania i wspólnie opracować plan leczenia dostosowany do Twoich potrzeb.",
                            "action": "Zobacz więcej",
                            "category": "",
                            "link": "/kontakt",
                            "image": "https://images.unsplash.com/photo-1503324010925-71cfe52dad2a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "3",
                            "title": "Pomoc w decyzji",
                            "content": "Nie jesteś pewien, jaki krok podjąć? Skontaktuj się z nami, a nasi specjaliści pomogą Ci podjąć najlepszą decyzję dotyczącą leczenia uzależnień. Jesteśmy tutaj, aby Cię wesprzeć.",
                            "action": "Zobacz więcej",
                            "category": "",
                            "link": "/kontakt",
                            "image": "https://images.unsplash.com/photo-1589190887320-d1b6af2bdac3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    )
}

export default AboutUs